//
// Testimonial and Comment (based on Blockquote component)
// -------------------------------------------------------

.testimonial,
.comment {
  .testimonial-footer {
    display: table;
    padding-top: $grid-vertical-step;
  }
  .blockquote-footer {
    margin-top: 0;
  }
  .testimonial-avatar {
    display: table-cell;
    width: $testimonial-avatar-size;
    vertical-align: middle;
    > img {
      display: block;
      width: $testimonial-avatar-size;
      height: $testimonial-avatar-size;
      border-radius: 50%;
    }
  }
}
.comment {
  padding: $grid-vertical-step;
  &::before { display: none; }
  .comment {
    margin-top: $grid-vertical-step;
    padding-bottom: 0;
    border-top: 1px solid $border-color;
    box-shadow: none;
  }
}

// Alignment
.text-center {
  .testimonial,
  &.testimonial {
    .testimonial-footer { margin: auto; }
    .blockquote-footer {
      text-align: left;
      &::before {
        left: 0;
        margin-left: 0;
      }
    }
  }
}
.text-right {
  .testimonial,
  &.testimonial {
    .testimonial-footer {
      margin-right: 0;
      margin-left: auto;
    }
  }
}

// Inside Carousel
.testimonials-carousel {
  .owl-item {
    padding: {
      right: 22px;
      left: 22px;
    }
  }
  .owl-dots {
    margin-top: -16px;
    padding-top: 0;
  }
  .testimonial {
    margin: {
      top: 8px;
      bottom: 38px;
    }
  }
}
