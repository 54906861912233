//
// Buttons
// --------------------------------------------------

// Basic Styles
// --------------------------------------------------

.btn {
  display: inline-block;
  position: relative;
  height: $btn-height-md;
  padding: 0 28px;
  transform: translateZ(0);
  transition: .3s;
  border: 0;
  background: {
    color: transparent;
    image: none;
  }
  color: $gray-dark;
  font: {
    family: $font-family-base;
    size: $font-size-button-md;
    weight: $btn-font-weight;
  }
  letter-spacing: $btn-letter-spacing;
  line-height: $btn-height-md;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
  z-index: 1;
  text: {
    transform: $btn-text-transform;
    decoration: none;
    align: center;
  }
  touch-action: manipulation;
  user-select: none;

  &:hover,
  &:focus {
    outline: none;
    background-image: none;
    text-decoration: none;
    box-shadow: none;
  }
  &::before,
  &::after {
    transition: .2s;
    &:not(.btn-style-11) { transform: translateZ(0); }
  }
  > i, > img {
    display: inline-block;
    font-size: 1.1em;
    vertical-align: middle;
  }
  > img {
    width: 20px;
    margin: {
      top: -1px;
      right: 6px;
    }
  }

  // Disabled
  &[disabled],
  &.disabled {
    cursor: not-allowed;
    opacity: .5;
    &:hover { box-shadow: none !important; }
  }

  // Button sizes
  &.btn-lg {
    height: $btn-height-lg;
    font-size: $font-size-button-lg;
    line-height: $btn-height-lg;
  }
  &.btn-sm {
    height: $btn-height-sm;
    padding: 0 20px;
    font-size: $font-size-button-sm;
    line-height: $btn-height-sm;
  }
  &.btn-xs {
    height: $btn-height-xs;
    padding: 0 12px;
    font-size: $font-size-button-xs;
    line-height: $btn-height-xs;
  }

  // Block Button
  &.btn-block {
    display: block;
    width: 100%;
    padding: {
      right: 15px !important;
      left: 15px !important;
    }
  }
}
// Edge icon alignment fix
@supports (-ms-ime-align: auto) {
  .btn > i { margin-top: -2px; }
}


// Style 1
// --------------------------------------------------

.btn-style-1 {
  &.btn-primary {
    background-color: $primary;
    color: $white;
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($primary, .24);
    }
    &:active {
      background-color: darken($primary, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-accent {
    background-color: $accent;
    color: $white;
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($accent, .24);
    }
    &:active {
      background-color: darken($accent, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-info {
    background-color: $info;
    color: $white;
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($info, .24);
    }
    &:active {
      background-color: darken($info, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-success {
    background-color: $success;
    color: $white;
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($success, .24);
    }
    &:active {
      background-color: darken($success, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-warning {
    background-color: $warning;
    color: $white;
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($warning, .24);
    }
    &:active {
      background-color: darken($warning, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-danger {
    background-color: $danger;
    color: $white;
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($danger, .24);
    }
    &:active {
      background-color: darken($danger, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-secondary {
    border: 1px solid $border-color;
    background-color: $gray-lighter;
    line-height: ($btn-height-md - 2);
    &.btn-lg { line-height: ($btn-height-lg - 2); }
    &.btn-sm { line-height: ($btn-height-sm - 2); }
    &:hover {
      color: $gray-dark;
      box-shadow: 0 12px 18.8px 1.2px rgba($gray-darker, .15);
    }
    &:active {
      background-color: $gray-light;
      color: $gray-dark;
      box-shadow: none;
    }
  }
  &.btn-white {
    background-color: $white;
    &:hover {
      color: $gray-dark;
      box-shadow: 0 12px 18.8px 1.2px rgba($gray-darker, .24);
    }
    &:active {
      color: $gray-dark;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-gradient {
    color: $white;
    @extend .bg-gradient;
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($gradient-color-1, .24);
    }
    &:active {
      box-shadow: 0 6px 8px .2px rgba($gradient-color-1, .24);
    }
  }
}


// Style 2
// --------------------------------------------------

.btn-style-2 {
  padding: 0 36px;
  &.btn-sm { padding: 0 28px; }
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 18px);
    height: 100%;
    transform: translate(-50%, -50%) skew(-35deg, 0);
    content: '';
    z-index: -1;
  }

  &.btn-primary {
    color: $white;
    &::before { background-color: $primary; }
    &:hover::before {
      box-shadow: 0 12px 18.8px 1.2px rgba($primary, .24);
    }
    &:active::before {
      background-color: darken($primary, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-accent {
    color: $white;
    &::before { background-color: $accent; }
    &:hover::before {
      box-shadow: 0 12px 18.8px 1.2px rgba($accent, .24);
    }
    &:active::before {
      background-color: darken($accent, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-info {
    color: $white;
    &::before { background-color: $info; }
    &:hover::before {
      box-shadow: 0 12px 18.8px 1.2px rgba($info, .24);
    }
    &:active::before {
      background-color: darken($info, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-success {
    color: $white;
    &::before { background-color: $success; }
    &:hover::before {
      box-shadow: 0 12px 18.8px 1.2px rgba($success, .24);
    }
    &:active::before {
      background-color: darken($success, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-warning {
    color: $white;
    &::before { background-color: $warning; }
    &:hover::before {
      box-shadow: 0 12px 18.8px 1.2px rgba($warning, .24);
    }
    &:active::before {
      background-color: darken($warning, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-danger {
    color: $white;
    &::before { background-color: $danger; }
    &:hover::before {
      box-shadow: 0 12px 18.8px 1.2px rgba($danger, .24);
    }
    &:active::before {
      background-color: darken($danger, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-secondary {
    &.btn-lg { line-height: ($btn-height-lg - 2); }
    &.btn-sm { line-height: ($btn-height-sm - 2); }
    &::before {
      border: 1px solid $border-color;
      background-color: $gray-lighter;
    }
    &:hover, &:focus { color: $gray-dark; }
    &:hover::before {
      box-shadow: 0 12px 18.8px 1.2px rgba($gray-darker, .15);
    }
    &:active::before {
      background-color: $gray-light;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .15);
    }
  }
  &.btn-white {
    &::before { background-color: $white; }
    &:hover, &:focus { color: $gray-dark; }
    &:hover::before {
      box-shadow: 0 12px 18.8px 1.2px rgba($gray-darker, .24);
    }
    &:active::before {
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-gradient {
    color: $white;
    &::before { @extend .bg-gradient; }
    &:hover::before {
      box-shadow: 0 12px 18.8px 1.2px rgba($gradient-color-1, .24);
    }
    &:active::before {
      box-shadow: 0 6px 8px .2px rgba($gradient-color-1, .24);
    }
  }
}


// Style 3
// --------------------------------------------------

.btn-style-3 {
  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .3s;
    content: '';
    z-index: -1;
  }
  &::before {
    top: 7px;
    left: -7px;
  }

  &.btn-primary {
    background-color: $primary;
    color: $white;
    &::before,
    &::after { border: 1px solid $primary;}
    &::after { background-color: $primary;}
    &:hover {
      color: $primary;
      &::before { background-color: $primary;}
      &::after { background-color: $white;}
    }
  }
  &.btn-accent {
    background-color: $accent;
    color: $white;
    &::before,
    &::after { border: 1px solid $accent;}
    &::after { background-color: $accent;}
    &:hover {
      color: $accent;
      &::before { background-color: $accent;}
      &::after { background-color: $white;}
    }
  }
  &.btn-info {
    background-color: $info;
    color: $white;
    &::before,
    &::after { border: 1px solid $info;}
    &::after { background-color: $info;}
    &:hover {
      color: $info;
      &::before { background-color: $info;}
      &::after { background-color: $white;}
    }
  }
  &.btn-success {
    background-color: $success;
    color: $white;
    &::before,
    &::after { border: 1px solid $success;}
    &::after { background-color: $success;}
    &:hover {
      color: $success;
      &::before { background-color: $success;}
      &::after { background-color: $white;}
    }
  }
  &.btn-warning {
    background-color: $warning;
    color: $white;
    &::before,
    &::after { border: 1px solid $warning;}
    &::after { background-color: $warning;}
    &:hover {
      color: $warning;
      &::before { background-color: $warning;}
      &::after { background-color: $white;}
    }
  }
  &.btn-danger {
    background-color: $danger;
    color: $white;
    &::before,
    &::after { border: 1px solid $danger;}
    &::after { background-color: $danger;}
    &:hover {
      color: $danger;
      &::before { background-color: $danger;}
      &::after { background-color: $white;}
    }
  }
  &.btn-secondary {
    background-color: $primary;
    &::before,
    &::after { border: 1px solid $border-color;}
    &::after { background-color: $gray-lighter;}
    &:hover {
      color: $gray-dark;
      &::before { background-color: $gray-lighter;}
      &::after { background-color: $white;}
    }
  }
  &.btn-white {
    &::before,
    &::after { border: 1px solid $white;}
    &::after { background-color: $white; }
    &:hover { color: $gray-dark; }
  }
  &.btn-gradient {
    color: $white;
    &::before,
    &::after { border: 1px solid $gradient-color-1;}
    &::after { @extend .bg-gradient; }
    &:hover {
      color: $gradient-color-1;
      &::before { @extend .bg-gradient; }
      &::after { background: $white !important;}
    }
  }
}


// Style 4
// --------------------------------------------------

.btn-style-4 {
  &::after {
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $white;
    content: '';
    z-index: -1;
  }
  &:hover {
    &::after { height: 5px; }
  }

  &.btn-primary {
    background-color: $primary;
    color: $white;
    &:active {
      background-color: darken($primary, 10%);
    }
  }
  &.btn-accent {
    background-color: $accent;
    color: $white;
    &:active {
      background-color: darken($accent, 10%);
    }
  }
  &.btn-info {
    background-color: $info;
    color: $white;
    &:active {
      background-color: darken($info, 10%);
    }
  }
  &.btn-success {
    background-color: $success;
    color: $white;
    &:active {
      background-color: darken($success, 10%);
    }
  }
  &.btn-warning {
    background-color: $warning;
    color: $white;
    &:active {
      background-color: darken($warning, 10%);
    }
  }
  &.btn-danger {
    background-color: $danger;
    color: $white;
    &:active {
      background-color: darken($danger, 10%);
    }
  }
  &.btn-secondary {
    background-color: darken($gray-lighter, 5%);
    &:hover { color: $gray-dark; }
    &:active {
      background-color: darken($gray-light, 8%);
    }
  }
  &.btn-gradient {
    color: $white;
    @extend .bg-gradient;
  }
}


// Style 5
// --------------------------------------------------

.btn-style-5 {
  padding-left: 42px;
  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 15px;
    width: 30px;
    height: 100%;
    transform: skew(-35deg, 0);
    content: '';
    z-index: -1;
  }
  &::after {
    position: absolute;
    left: 30px;
    width: calc(100% - 30px);
    transform: translate(0);
    z-index: -2;
  }
  &.btn-lg {
    &::before,
    &::after {
      left: 15px;
      width: 39px;
    }
    &::after {
      left: 35px;
      width: calc(100% - 35px);
    }
  }
  &.btn-sm { padding-left: 35px;}

  &.btn-primary {
    color: $white;
    &::before,
    &::after { background-color: $primary; }
    &:hover::after {
      box-shadow: -9px 12px 18.8px 1.2px rgba($primary, .24);
    }
    &:active {
      &::before,
      &::after { background-color: darken($primary, 10%); }
      &::after {
        box-shadow: -11px 6px 19px .2px rgba($gray-dark, .24);
      }
    }
  }
  &.btn-accent {
    color: $white;
    &::before,
    &::after { background-color: $accent; }
    &:hover::after {
      box-shadow: -9px 12px 18.8px 1.2px rgba($accent, .24);
    }
    &:active {
      &::before,
      &::after { background-color: darken($accent, 10%); }
      &::after {
        box-shadow: -11px 6px 19px .2px rgba($gray-dark, .24);
      }
    }
  }
  &.btn-info {
    color: $white;
    &::before,
    &::after { background-color: $info; }
    &:hover::after {
      box-shadow: -9px 12px 18.8px 1.2px rgba($info, .24);
    }
    &:active {
      &::before,
      &::after { background-color: darken($info, 10%); }
      &::after {
        box-shadow: -11px 6px 19px .2px rgba($gray-dark, .24);
      }
    }
  }
  &.btn-success {
    color: $white;
    &::before,
    &::after { background-color: $success; }
    &:hover::after {
      box-shadow: -9px 12px 18.8px 1.2px rgba($success, .24);
    }
    &:active {
      &::before,
      &::after { background-color: darken($success, 10%); }
      &::after {
        box-shadow: -11px 6px 19px .2px rgba($gray-dark, .24);
      }
    }
  }
  &.btn-warning {
    color: $white;
    &::before,
    &::after { background-color: $warning; }
    &:hover::after {
      box-shadow: -9px 12px 18.8px 1.2px rgba($warning, .24);
    }
    &:active {
      &::before,
      &::after { background-color: darken($warning, 10%); }
      &::after {
        box-shadow: -11px 6px 19px .2px rgba($gray-dark, .24);
      }
    }
  }
  &.btn-danger {
    color: $white;
    &::before,
    &::after { background-color: $danger; }
    &:hover::after {
      box-shadow: -9px 12px 18.8px 1.2px rgba($danger, .24);
    }
    &:active {
      &::before,
      &::after { background-color: darken($danger, 10%); }
      &::after {
        box-shadow: -11px 6px 19px .2px rgba($gray-dark, .24);
      }
    }
  }
  &.btn-secondary {
    &.btn-lg { line-height: ($btn-height-lg - 2); }
    &.btn-sm { line-height: ($btn-height-sm - 2); }
    &::before,
    &::after {
      border: 1px solid $border-color;
      background-color: $gray-lighter;
    }
    &::before { border-right: 0;}
    &:hover {
      color: $gray-dark;
      &::after {
        box-shadow: -9px 12px 18.8px 1.2px rgba($gray-darker, .15);
      }
    }
    &:active {
      &::before,
      &::after { background-color: $gray-light; }
      &::after { box-shadow: none; }
    }
  }
  &.btn-white {
    &::before,
    &::after { background-color: $white; }
    &:hover {
      color: $gray-dark;
      &::after {
        box-shadow: -9px 12px 18.8px 1.2px rgba($gray-darker, .24);
      }
    }
    &:active {
      &::after { box-shadow: -11px 6px 19px .2px rgba($gray-dark, .24);}
    }
  }
  &.btn-gradient {
    color: $white;
    &::before {
      background-color: $gradient-color-1;
    }
    &::after { @extend .bg-gradient; }
    &:hover::after {
      box-shadow: -9px 12px 18.8px 1.2px rgba($accent, .24);
    }
    &:active {
      &::after { box-shadow: -11px 6px 19px .2px rgba($gray-dark, .24);}
    }
  }
}


// Style 6
// --------------------------------------------------

.btn-style-6 {
  position: relative;
  border-radius: floor($btn-height-md / 2);
  box-shadow: 0 6px 8px .2px rgba($gray-dark, .14);
  &.btn-icon-right,
  &.btn-icon-left {
    i {
      display: block;
      position: absolute;
      top: 50%;
      left: 7px;
      width: 31px;
      height: 31px;
      margin-top: -15px;
      transition: all .3s;
      border-radius: 50%;
      line-height: 30px;
      &::before { margin-left: 2px; }
    }
  }
  &.btn-icon-left { padding-left: 46px; }
  &.btn-icon-right {
    padding-right: 46px;
    i {
      right: 7px;
      left: auto;
    }
  }

  &.btn-lg {
    border-radius: floor($btn-height-lg / 2);
  }
  &.btn-sm {
    border-radius: floor($btn-height-sm / 2);
    &.btn-icon-right,
    &.btn-icon-left {
      i {
        width: 26px;
        height: 26px;
        margin-top: -13px;
        line-height: 26px;
      }
    }
    &.btn-icon-left {
      padding-left: 34px;
      i { left: 2px; }
    }
    &.btn-icon-right {
      padding-right: 34px;
      i {
        right: 2px;
        left: auto;
      }
    }
  }

  &.btn-primary {
    background-color: $primary;
    color: $white;
    &.btn-icon-right,
    &.btn-icon-left {
      i { background-color: darken($primary, 10%); }
    }
    &:hover {
      background-color: darken($primary, 10%);
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &.btn-icon-right,
      &.btn-icon-left {
        i {
          background-color: $white;
          color: $primary;
        }
      }
    }
  }
  &.btn-accent {
    background-color: $accent;
    color: $white;
    &.btn-icon-right,
    &.btn-icon-left {
      i { background-color: darken($accent, 10%); }
    }
    &:hover {
      background-color: darken($accent, 10%);
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &.btn-icon-right,
      &.btn-icon-left {
        i {
          background-color: $white;
          color: $accent;
        }
      }
    }
  }
  &.btn-info {
    background-color: $info;
    color: $white;
    &.btn-icon-right,
    &.btn-icon-left {
      i { background-color: darken($info, 10%); }
    }
    &:hover {
      background-color: darken($info, 10%);
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &.btn-icon-right,
      &.btn-icon-left {
        i {
          background-color: $white;
          color: $info;
        }
      }
    }
  }
  &.btn-success {
    background-color: $success;
    color: $white;
    &.btn-icon-right,
    &.btn-icon-left {
      i { background-color: darken($success, 10%); }
    }
    &:hover {
      background-color: darken($success, 10%);
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &.btn-icon-right,
      &.btn-icon-left {
        i {
          background-color: $white;
          color: $success;
        }
      }
    }
  }
  &.btn-warning {
    background-color: $warning;
    color: $white;
    &.btn-icon-right,
    &.btn-icon-left {
      i { background-color: darken($warning, 10%); }
    }
    &:hover {
      background-color: darken($warning, 10%);
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &.btn-icon-right,
      &.btn-icon-left {
        i {
          background-color: $white;
          color: $warning;
        }
      }
    }
  }
  &.btn-danger {
    background-color: $danger;
    color: $white;
    &.btn-icon-right,
    &.btn-icon-left {
      i { background-color: darken($danger, 10%); }
    }
    &:hover {
      background-color: darken($danger, 10%);
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &.btn-icon-right,
      &.btn-icon-left {
        i {
          background-color: $white;
          color: $danger;
        }
      }
    }
  }
  &.btn-secondary {
    border: 1px solid $border-color;
    background-color: $gray-lighter;
    color: $gray-dark;
    line-height: ($btn-height-md - 2);
    box-shadow: none;
    &.btn-lg { line-height: ($btn-height-lg - 2); }
    &.btn-sm { line-height: ($btn-height-sm - 2); }
    &.btn-icon-right,
    &.btn-icon-left {
      i {
        border: 1px solid $border-color;
        background-color: $white;
        color: $gray-dark;
      }
    }
    &:hover {
      background-color: $gray-light;
      color: $gray-dark;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &.btn-icon-right,
      &.btn-icon-left {
        i {
          background-color: $white;
          color: $gray-dark;
        }
      }
    }
  }
  &.btn-white {
    background-color: $white;
    &.btn-icon-right,
    &.btn-icon-left {
      i {
        border: 1px solid $border-color;
        background-color: $white;
        color: $gray-dark;
      }
    }
    &:hover {
      color: $gray-dark;
      box-shadow: 0 12px 18.8px 1.2px rgba($gray-darker, .24);
    }
    &:active {
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-gradient {
    color: $white;
    @extend .bg-gradient;
    &.btn-icon-right,
    &.btn-icon-left {
      i {
        background-color: $white;
        color: $primary;
      }
    }
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($gradient-color-1, .24);
    }
  }
}


// Style 7
// --------------------------------------------------

.btn-style-7 {
  overflow: hidden;
  &::after {
    position: absolute;
    top: -20%;
    left: -43px;
    width: 50px;
    height: 200%;
    transform: rotate(35deg);
    transform-origin: right;
    transition: left .25s ease-in-out;
    content: '';
    z-index: -1;
  }
  &:hover {
    &::after { left: -80px; }
  }

  &.btn-primary {
    background-color: $primary;
    color: $white;
    &::after {
      background-color: darken($primary, 10%);
    }
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($primary, .24);
    }
    &:active {
      background-color: darken($primary, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-accent {
    background-color: $accent;
    color: $white;
    &::after {
      background-color: darken($accent, 10%);
    }
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($accent, .24);
    }
    &:active {
      background-color: darken($accent, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-info {
    background-color: $info;
    color: $white;
    &::after {
      background-color: darken($info, 10%);
    }
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($info, .24);
    }
    &:active {
      background-color: darken($info, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-success {
    background-color: $success;
    color: $white;
    &::after {
      background-color: darken($success, 10%);
    }
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($success, .24);
    }
    &:active {
      background-color: darken($success, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-warning {
    background-color: $warning;
    color: $white;
    &::after {
      background-color: darken($warning, 10%);
    }
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($warning, .24);
    }
    &:active {
      background-color: darken($warning, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-danger {
    background-color: $danger;
    color: $white;
    &::after {
      background-color: darken($danger, 10%);
    }
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($danger, .24);
    }
    &:active {
      background-color: darken($danger, 10%);
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-secondary {
    border: 1px solid $border-color;
    background-color: $gray-lighter;
    line-height: ($btn-height-md - 2);
    &.btn-lg { line-height: ($btn-height-lg - 2); }
    &.btn-sm { line-height: ($btn-height-sm - 2); }
    &::after {
      background-color: $gray-light;
    }
    &:hover {
      color: $gray-dark;
      box-shadow: 0 12px 18.8px 1.2px rgba($gray-darker, .15);
    }
    &:active {
      background-color: $gray-light;
      box-shadow: none;
    }
  }
  &.btn-white {
    background-color: $white;
    &::after {
      background-color: $gray-light;
    }
    &:hover {
      color: $gray-dark;
      box-shadow: 0 12px 18.8px 1.2px rgba($gray-darker, .24);
    }
    &:active {
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-gradient {
    color: $white;
    @extend .bg-gradient;
    &::after {
      background-color: darken($gradient-color-1, 10%);
    }
    &:hover {
      box-shadow: 0 12px 18.8px 1.2px rgba($primary, .24);
    }
    &:active {
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
}


// Style 8
// --------------------------------------------------

.btn-style-8 {
  &,
  &::before,
  &::after { border-radius: floor($btn-height-md / 2); }
  
  &.btn-lg {
    &,
    &::before,
    &::after { border-radius: floor($btn-height-lg / 2);}
  }
  &.btn-sm {
    &,
    &::before,
    &::after { border-radius: floor($btn-height-sm / 2);}
  }

  &::before,
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all .3s;
    content: '';
    z-index: -2;
    @extend %center-xy;
  }
  &::after {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    z-index: -1;
  }

  &.btn-primary {
    color: $white;
    &::before {
      border: 1px solid $primary;
      background-color: transparent;
    }
    &::after { background-color: $primary; }

    &:hover {
      &::before {
        border: 1px solid $primary;
        background-color: $primary;
      }
    }
    &:active {
      &::before {
        border: 1px solid darken($primary, 10%);
        background-color: darken($primary, 10%);
      }
      &::after { background-color: darken($primary, 10%); }
    }
  }
  &.btn-accent {
    color: $white;
    &::before {
      border: 1px solid $accent;
      background-color: transparent;
    }
    &::after { background-color: $accent; }

    &:hover {
      &::before {
        border: 1px solid $accent;
        background-color: $accent;
      }
    }
    &:active {
      &::before {
        border: 1px solid darken($accent, 10%);
        background-color: darken($accent, 10%);
      }
      &::after { background-color: darken($accent, 10%); }
    }
  }
  &.btn-info {
    color: $white;
    &::before {
      border: 1px solid $info;
      background-color: transparent;
    }
    &::after { background-color: $info; }

    &:hover {
      &::before {
        border: 1px solid $info;
        background-color: $info;
      }
    }
    &:active {
      &::before {
        border: 1px solid darken($info, 10%);
        background-color: darken($info, 10%);
      }
      &::after { background-color: darken($info, 10%); }
    }
  }
  &.btn-success {
    color: $white;
    &::before {
      border: 1px solid $success;
      background-color: transparent;
    }
    &::after { background-color: $success; }

    &:hover {
      &::before {
        border: 1px solid $success;
        background-color: $success;
      }
    }
    &:active {
      &::before {
        border: 1px solid darken($success, 10%);
        background-color: darken($success, 10%);
      }
      &::after { background-color: darken($success, 10%); }
    }
  }
  &.btn-warning {
    color: $white;
    &::before {
      border: 1px solid $warning;
      background-color: transparent;
    }
    &::after { background-color: $warning; }

    &:hover {
      &::before {
        border: 1px solid $warning;
        background-color: $warning;
      }
    }
    &:active {
      &::before {
        border: 1px solid darken($warning, 10%);
        background-color: darken($warning, 10%);
      }
      &::after { background-color: darken($warning, 10%); }
    }
  }
  &.btn-danger {
    color: $white;
    &::before {
      border: 1px solid $danger;
      background-color: transparent;
    }
    &::after { background-color: $danger; }

    &:hover {
      &::before {
        border: 1px solid $danger;
        background-color: $danger;
      }
    }
    &:active {
      &::before {
        border: 1px solid darken($danger, 10%);
        background-color: darken($danger, 10%);
      }
      &::after { background-color: darken($danger, 10%); }
    }
  }
  &.btn-secondary {
    &.btn-lg { line-height: ($btn-height-lg - 2); }
    &.btn-sm { line-height: ($btn-height-sm - 2); }
    &::before {
      border: 1px solid $border-color;
      background-color: transparent;
    }
    &::after { background-color: darken($gray-lighter, 3%); }

    &:hover {
      color: $gray-dark;
      &::before {
        border: 1px solid $border-color;
        background-color: darken($gray-lighter, 3%);
      }
    }
    &:active {
      &::before {
        border: 1px solid $gray-light;
        background-color: $gray-light;
      }
      &::after { background-color: $gray-light; }
    }
  }
  &.btn-white {
    &::before {
      border: 1px solid $white;
      background-color: transparent;
    }
    &::after { background-color: $white;}
    &:hover {
      color: $gray-dark;
      &::before {
        border: 1px solid $white;
        background-color: $white;
      }
    }
  }
  &.btn-gradient {
    color: $white;
    &::before {
      border: 1px solid $gradient-color-1;
      background: transparent;
    }
    &::after { @extend .bg-gradient;}

    &:hover {
      &::before {
        @extend .bg-gradient
      }
    }
  }
}


// Style 9
// --------------------------------------------------

.btn-style-9 {
  background-color: $white;
  box-shadow: 0 12px 18.8px 1.2px rgba($gray-dark, .15);
  overflow: hidden;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    transition: all .25s;
    content: '';
    z-index: -2;
  }
  &:hover {
    &::before { width: 100%;}
  }
  &:focus {
    box-shadow: 0 12px 18.8px 1.2px rgba($gray-dark, .24);
  }
  &.btn-primary {
    &::before {
      background-color: $primary;
    }
    &:hover {
      color: $white;
      box-shadow: 0 12px 18.8px 1.2px rgba($primary, .24);
    }
    &:active {
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &::before { background-color: darken($primary, 10%);}
    }
  }
  &.btn-accent {
    &::before {
      background-color: $accent;
    }
    &:hover {
      color: $white;
      box-shadow: 0 12px 18.8px 1.2px rgba($accent, .24);
    }
    &:active {
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &::before { background-color: darken($accent, 10%);}
    }
  }
  &.btn-info {
    &::before {
      background-color: $info;
    }
    &:hover {
      color: $white;
      box-shadow: 0 12px 18.8px 1.2px rgba($info, .24);
    }
    &:active {
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &::before { background-color: darken($info, 10%);}
    }
  }
  &.btn-success {
    &::before {
      background-color: $success;
    }
    &:hover {
      color: $white;
      box-shadow: 0 12px 18.8px 1.2px rgba($success, .24);
    }
    &:active {
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &::before { background-color: darken($success, 10%);}
    }
  }
  &.btn-warning {
    &::before {
      background-color: $warning;
    }
    &:hover {
      color: $white;
      box-shadow: 0 12px 18.8px 1.2px rgba($warning, .24);
    }
    &:active {
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &::before { background-color: darken($warning, 10%);}
    }
  }
  &.btn-danger {
    &::before {
      background-color: $danger;
    }
    &:hover {
      color: $white;
      box-shadow: 0 12px 18.8px 1.2px rgba($danger, .24);
    }
    &:active {
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
      &::before { background-color: darken($danger, 10%);}
    }
  }
  &.btn-secondary {
    background-color: $white;
    &::before { background-color: darken($border-color, 6%); }
    &:hover {
      color: $gray-dark;
      box-shadow: 0 12px 18.8px 1.2px rgba($gray-darker, .24);
      &::before { background-color: darken($gray-lighter, 5%); }
    }
    &:active {
      background-color: $gray-light;
      box-shadow: 0 6px 8px .2px rgba($gray-dark, .24);
    }
  }
  &.btn-gradient {
    background-color: $white;
    &::before {
      @extend .bg-gradient;
    }
    &:hover {
      color: $white;
      box-shadow: 0 12px 18.8px 1.2px rgba($gradient-color-1, .24);
    }
    &:active {
      color: $white;
      box-shadow: 0 6px 8px .2px rgba($gradient-color-1, .24);
    }
  }
}

// Button group
.btn-group { position: relative; }

// Scroll to Top Button
.scroll-to-top-btn {
  display: block;
  position: fixed;
  right: 16px;
  bottom: -($btn-height-md * 2);
  width: $btn-height-md;
  height: $btn-height-md;
  transition: bottom 400ms cubic-bezier(.68, -.55, .265, 1.55), opacity .3s, background-color .3s, border-color .3s;
  background-color: rgba($gray-darker, .3);
  color: $white;
  font-size: $font-size-h5;
  opacity: 0;
  z-index: 1050;
  text: {
    align: center;
    decoration: none;
  }
  &:hover {
    background-color: $gray-darker;
    color: $white;
  }
  &:focus, &:active { color: $white; }
  &.visible {
    bottom: 14px;
    opacity: 1;
  }
  > i { line-height: $btn-height-md; }
  @media (max-width: $screen-md) {
    bottom: -($btn-height-sm * 2);
    width: $btn-height-sm;
    height: $btn-height-sm;
    line-height: ($btn-height-sm - 5);
    > i { line-height: $btn-height-sm; }
  }
}
