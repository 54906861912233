//
// Badge (Override default Bootstrap badge colors)
// --------------------------------------------------

.badge {
  margin: {
    top: -3px;
    left: 7px;
  }
  padding: {
    top: .2em;
    bottom: .3em;
  }
  font: {
    size: floor($font-size-base * .6875);
    weight: 600;
  }
  vertical-align: middle;
  &:not(.badge-pill) { border-radius: 2px; }
  &:not(.badge-light) { color: $white; }
}
.badge-primary { background-color: $primary; }
.badge-secondary {
  border: 1px solid $border-color;
  background-color: $gray-lighter;
  color: $body-color !important;
}
.badge-primary { background-color: $primary; }
.badge-accent { background-color: $accent; }
.badge-info { background-color: $info; }
.badge-success { background-color: $success; }
.badge-warning { background-color: $warning; }
.badge-danger { background-color: $danger; }
.badge-dark { background-color: $gray-darker; }
