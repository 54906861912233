//
// Page Title
// --------------------------------------------------

.page-title {
  min-height: 250px;
  margin-bottom: floor($grid-vertical-step * 2.4); //~57px
  padding: {
    top: floor($grid-vertical-step * 1.3); //~31px
    bottom: floor($grid-vertical-step * 1.3); //~31px
  }
  background: {
    position: center center;
    color: $gray-lighter;
    repeat: no-repeat;
  }
  .page-title-heading {
    font-size: floor(($font-size-base * 2.8));
    letter-spacing: .034em;
    line-height: 1.2;
    text-shadow: 0 10px 24px rgba($black, .2);
    text-transform: uppercase;
    word-break: break-all;
  }
  .page-title-subheading {
    display: inline-block;
    padding: 10px 15px;
    background: darken($gray-lighter, 3%);
  }
  &.bg-dark .page-title-subheading {
    background: darken($gray-darker, 3%);
  }
  &.page-title-lg { min-height: 450px; }
  &.page-title-indent-left {
    padding-left: ($offcanvas-width + 15);
  }
  &.page-title-indent-right {
    padding-right: ($offcanvas-width + 15);
  }
  @media (max-width: $screen-md) {
    min-height: 150px;
    margin-bottom: floor($grid-vertical-step * 1.8); //~43px
    .page-title-heading {
      font-size: floor(($font-size-base * 2.2));
    }
    &.page-title-lg { min-height: 250px; }
  }
  @media (max-width: 1480px) {
    &.page-title-indent-left {
      padding-left: (($offcanvas-width + 15) - 50);
    }
    &.page-title-indent-right {
      padding-right: (($offcanvas-width + 15) - 50);
    }
  }
}

// Breadcrumbs
.breadcrumbs {
  display: block;
  margin: 0;
  padding: 0;
  padding-bottom: 2px;
  list-style: none;
  > li {
    display: inline-block;
    > a {
      display: block;
      position: relative;
      margin-left: 14px;
      padding-left: 18px;
      color: $primary;
      font: {
        size: floor($font-size-base * .6875);
        weight: $navi-link-font-weight;
      }
      letter-spacing: $navi-link-letter-spacing;
      text: {
        transform: $navi-link-text-transform;
        decoration: none;
      }
      &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 22px;
        margin-top: -11px;
        transform: rotate(35deg);
        background-color: $primary;
        content: '';
      }
    }
    &:first-child {
      > a {
        margin-left: 0;
        padding-left: 4px;
        &::before { display: none; }
      }
    }
  }
}
