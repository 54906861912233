//
// Shop specific styles
// --------------------------------------------------

// Product Card
.product-card {
  position: relative;
  max-width: 380px;
  padding: {
    top: floor($grid-vertical-step / 2);
    bottom: floor($grid-vertical-step * 1.8);
  }
  transition: all .35s;
  border: 1px solid $border-color;
  .product-head {
    padding: 0 15px 8px;
    .badge { margin: 0; }
  }
  .product-thumb {
    display: block;
    > img {
      display: block;
      width: 100%;
    }
  }
  .product-card-body {
    padding: 0 floor($grid-vertical-step / 1.2);
    text-align: center;
  }
  .product-meta {
    display: block;
    padding: 12px 0 2px;
    transition: color .25s;
    color: rgba($gray, .75);
    font: {
      size: $font-size-sm;
      weight: 600;
    }
    text-decoration: none;
    &:hover { color: $gray; }
  }
  .product-title {
    margin-bottom: floor($grid-vertical-step / 3);
    font-size: $font-size-lg;
    font-weight: bold;
    > a {
      transition: color .3s;
      color: $headings-color;
      text-decoration: none;
      &:hover { color: $primary; }
    }
  }
  .product-price {
    display: block;
    color: $body-color;
    font: {
      family: $font-family-headings;
      weight: normal;
    }
    > del {
      margin-right: 6px;
      color: rgba($gray, .75);
    }
  }
  .product-buttons-wrap {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
  }
  .product-buttons {
    display: table;
    margin: auto;
    background-color: $white;
    box-shadow: $box-shadow-shop-btn;
  }
  .product-button {
    display: table-cell;
    position: relative;
    width: 50px;
    height: 40px;
    border-right: 1px solid rgba($border-color, .6);
    &:last-child { border-right: 0; }
    > a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all .3s;
      color: $gray-dark;
      font-size: $font-size-lg;
      line-height: 40px;
      text: {
        align: center;
        decoration: none;
      }
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
  &:hover {
    border-color: transparent;
    box-shadow: $box-shadow-card;
  }
}

// Product Category Card
.product-category-card {
  display: block;
  max-width: 400px;
  text: {
    align: center;
    decoration: none !important;
  }
  .product-category-card-thumb {
    display: table;
    width: 100%;
    box-shadow: $box-shadow-card;
  }
  .product-category-card-body {
    padding: floor($grid-vertical-step / 1.2);
    padding-bottom: floor($grid-vertical-step * 1.2);
  }
  .main-img,
  .thumblist {
    display: table-cell;
    padding: 15px;
    vertical-align: middle;
    > img {
      display: block;
      width: 100%;
    }
  }
  .main-img {
    width: 65%;
    padding-right: 10px;
  }
  .thumblist {
    width: 35%;
    padding-left: 10px;
    > img:first-child { margin-bottom: 6px; }
  }
  .product-category-card-meta {
    display: block;
    padding-bottom: floor($grid-vertical-step / 2.5);
    color: rgba($gray, .75);
    font: {
      size: floor($font-size-base * .6875);
      weight: 600;
    }
  }
  .product-category-card-title {
    margin-bottom: 0;
    transition: color .3s;
    color: $headings-color;
    font-size: $font-size-xl;
  }
  &:hover .product-category-card-title { color: $primary; }
}

// Product Gallery
.product-gallery {
  position: relative;
  padding: 45px 15px 0;
  box-shadow: $box-shadow-card;
  .gallery-item {
    &::before { display: none !important; }
    &::after { box-shadow: $box-shadow-gallery-hover; }
  }
  .video-player-button,
  .badge {
    position: absolute;
    z-index: 5;
  }
  .badge {
    top: 15px;
    left: 15px;
    margin-left: 0;
  }
  .video-player-button {
    top: 0;
    right: 15px;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .product-thumbnails {
    display: block;
    margin: 0 -15px;
    padding: floor($grid-vertical-step / 2);
    border-top: 1px solid $border-color;
    list-style: none;
    text-align: center;
    > li {
      display: inline-block;
      margin: 10px 3px;
      > a {
        display: block;
        width: 94px;
        transition: all .25s;
        border: 1px solid transparent;
        background-color: $white;
        opacity: .75;
      }
      &:hover > a { opacity: 1; }
      &.active > a {
        border-color: $primary;
        cursor: default;
        opacity: 1;
      }
    }
  }
}

// Product Meta
.product-meta {
  padding-bottom: 10px;
  > a, > i {
    display: inline-block;
    margin-right: 5px;
    color: rgba($gray, .75);
    vertical-align: middle;
  }
  > i { margin-top: 2px; }
  > a {
    transition: color .25s;
    font: {
      size: floor($font-size-base * .8125);
      weight: 600;
    }
    text-decoration: none;
    &:hover { color: $gray; }
  }
}

// Cart
.cart-item {
  position: relative;
  margin-bottom: 30px;
  padding: 0 50px 0 10px;
  background-color: $white;
  box-shadow: $box-shadow-card;
  .cart-item-label {
    display: block;
    margin-bottom: 15px;
    color: $gray;
    font: {
      size: floor($font-size-base * .8125);
      weight: 600;
    }
    text-transform: uppercase;
  }
  .cart-item-product {
    display: table;
    width: 420px;
    text-decoration: none;
  }
  .cart-item-product-thumb,
  .cart-item-product-info {
    display: table-cell;
    vertical-align: top;
  }
  .cart-item-product-thumb {
    width: $cart-product-thumb-width;
    > img {
      display: block;
      width: 100%;
    }
  }
  .cart-item-product-info {
    padding: {
      top: 5px;
      left: 15px;
    }
    > span {
      display: block;
      margin-bottom: 2px;
      color: $body-color;
      font-size: $font-size-sm;
    }
  }
  .cart-item-product-title {
    margin-bottom: floor($grid-vertical-step / 3);
    transition: color, .3s;
    color: $headings-color;
    font: {
      size: $font-size-base;
      weight: bold;
    }
  }
  .cart-item-product:hover {
    .cart-item-product-title { color: $primary; }
  }
  .count-input {
    display: inline-block;
    width: 85px;
  }
  .remove-item { right: -10px !important; }
  @media (max-width: $screen-lg) {
    .cart-item-product { width: auto; }
    padding-right: 30px;
  }
  @media (max-width: $screen-md) {
    padding-right: 10px;
    padding-bottom: 15px;
    .cart-item-product {
      display: block;
      width: 100%;
      text-align: center;
    }
    .cart-item-product-thumb,
    .cart-item-product-info { display: block; }
    .cart-item-product-thumb { margin: 0 auto 10px; }
    .cart-item-product-info { padding-left: 0; }
    .cart-item-label { margin-bottom: 8px; }
  }
}

// Comparison Table
.comparison-table {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  table {
    min-width: 750px;
    table-layout: fixed;
  }
  .comparison-item {
    position: relative;
    margin-bottom: 10px;
    padding: 13px 12px 18px;
    background-color: $white;
    text-align: center;
    box-shadow: $box-shadow-card;
    .comparison-item-thumb {
      display: block;
      width: 80px;
      margin: {
        right: auto;
        bottom: 12px;
        left: auto;
      }
      > img {
        display: block;
        width: 100%;
      }
    }
    .comparison-item-title {
      display: block;
      margin-bottom: 14px;
      transition: color .25s;
      color: $gray-dark;
      font: {
        size: $font-size-md;
        weight: 600;
      }
      text-decoration: none;
      &:hover { color: $primary; }
    }
  }
}

// Remove Buttom
.remove-item {
  display: block;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 22px;
  height: 22px;
  padding-left: 1px;
  border-radius: 50%;
  background-color: $danger;
  color: $white;
  line-height: 23px;
  text-align: center;
  box-shadow: 0 3px 12px 0 rgba($danger, .5);
  cursor: pointer;
}

// Interactive Credit Card
.card-wrapper {
  margin: 30px -15px;
  @media (max-width: $screen-sm) {
    .jp-card-container { width: 260px !important; }
    .jp-card { min-width: 250px !important; }
  }
}
