//
// Video Player
// --------------------------------------------------

.video-player-button,
.video-player-label {
  display: inline-block;
  vertical-align: middle;
}
.video-player-button {
  width: $video-player-btn-size;
  height: $video-player-btn-size;
  padding-left: 4px;
  transition: all .3s;
  border-radius: 50%;
  background-color: $white;
  color: $headings-color;
  font-size: $font-size-lg;
  line-height: $video-player-btn-size;
  box-shadow: $box-shadow-video-btn;
  text: {
    align: center;
    decoration: none;
  }
  &:hover {
    color: $primary;
    box-shadow: $box-shadow-video-btn-hover;
  }
}
