//
// Scaffolding
// --------------------------------------------------

html * {
  @include font-smoothing;
}
html, body {
  height: 100%;
}
body {
  background: {
    position: center;
    color: $body-bg;
    repeat: no-repeat;
    size: cover;
    attachment: fixed;
  }
  color: $body-color;
  font: {
    family: $font-family-base;
    size: $font-size-md;
    weight: $font-weight-base;
  }
  line-height: $line-height-base;
  text-transform: $text-transform-base;
}

// Links
a {
  color: $link-color;
  text-decoration: underline;

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
  &:focus { outline: none; }
}

// Small tag
.small, small { font-size: 80%; }

// Images
// Responsive images (ensure images don't scale beyond their parents)
img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
svg { max-width: 100%; }

// Responsive iframes
iframe {
  width: 100%;
  border: 0;
}

// Thumbnails
.img-thumbnail {
  padding: 6px;
  border: 1px solid darken($border-color, 3%);
}

// Figures
.figure {
  position: relative;
  padding: 15px;
  background-color: $white;
  box-shadow: $box-shadow-card;
  > .badge {
    position: absolute;
    top: 25px;
    left: 25px;
    margin: 0;
    z-index: 5;
  }
}
.figure-img {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.figure-caption {
  font: {
    size: floor($font-size-base * .8125);
    weight: 600;
  }
}

// Horizontal rule
hr {
  margin: 0;
  border: 0;
  border-top: 1px solid $border-color;
  &.hr-light { border-top-color: $border-light-color; }
}

// Text Selection Color
::selection {
  background: $gray-darker;
  color: $white;
}
::-moz-selection {
  background: $gray-darker;
  color: $white;
}

// Bootstrap Overrides 
@media (min-width: $screen-xl) {
  .container {
    max-width: 1170px;
  }
}
@media (max-width: $screen-xl) {
  .container {
    width: 100% !important;
    max-width: 100% !important;
  }
}
.container-fluid {
  max-width: 1920px;
  margin: {
    right: auto;
    left: auto;
  }
  padding: {
    right: 30px;
    left: 30px;
  }
  @media (max-width: $screen-xl) { padding: 0 15px; }
}
