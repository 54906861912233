//
// Team
// --------------------------------------------------

// General for all styles
.team-card-style-1,
.team-card-style-3,
.team-card-style-5 {
  position: relative;
  max-width: 360px;
  text-align: center;
}
.team-contact-link {
  display: block;
  margin-top: floor($grid-vertical-step / 5);
  transition: all .25s;
  font: {
    size: $font-size-sm;
    weight: 700;
  }
  text-decoration: none;
  > i {
    display: inline-block;
    font-size: 1.1em;
    vertical-align: middle;
  }
}
.team-card-style-1,
.team-card-style-3,
.team-card-style-4 {
  .team-position {
    display: block;
    margin-bottom: floor($grid-vertical-step / 3);
    color: $gray;
    font: {
      size: $font-size-sm;
      weight: 700;
    }
    opacity: .6;
  }
}
.team-card-style-3,
.team-card-style-4,
.team-card-style-5 {
  .team-name {
    margin-bottom: floor($grid-vertical-step / 1.5);
    font: {
      size: $font-size-xl;
      weight: 600;
    }
  }
}
.team-thumb > img {
  display: block;
  width: 100%;
}

// Style 1 (Specific styles)
.team-card-style-1 {
  padding-bottom: floor($grid-vertical-step * 1.5);
  > * {
    position: relative;
    z-index: 5;
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: all .3s .12s;
    @extend .bg-gradient-vertical;
    content: '';
    opacity: 0;
  }
  .team-card-inner {
    margin-bottom: floor($grid-vertical-step / 1.5);
    padding: {
      top: floor($grid-vertical-step * 2);
      right: floor($grid-vertical-step / 1.5);
      bottom: floor($grid-vertical-step / 1.2);
      left: floor($grid-vertical-step / 1.5);
    }
    background-color: $white;
    box-shadow: $box-shadow-base;
  }
  .team-thumb {
    width: 108px;
    height: 108px;
    margin: auto;
    margin-bottom: floor($grid-vertical-step / 1.5);
    border-radius: 50%;
    overflow: hidden;
  }
  .team-social-bar {
    margin: {
      top: floor($grid-vertical-step / 1.5);
      bottom: floor($grid-vertical-step / 3);
    }
    transform: scale(.8);
  }
  .team-contact-link {
    transition-delay: .12s;
    color: $gray;
    opacity: .6;
    &:hover {
      color: $gray;
      opacity: 1;
    }
  }
  .team-card-inner,
  .team-thumb,
  .team-social-bar { transition: all .3s .12s; }
  .team-position,
  .team-name { transition: color .3s .12s; }
  .team-name {
    margin-bottom: 0;
    font: {
      size: floor($font-size-base * 1.25);
      weight: bold;
    }
  }
}
.no-touchevents .team-card-style-1:hover {
  &::before {
    height: 100%;
    box-shadow: $box-shadow-base;
    opacity: 1;
  }
  .team-card-inner {
    background-color: transparent;
    box-shadow: none;
  }
  .team-thumb { transform: scale(1.1); }
  .team-social-bar { transform: scale(1); }
  .team-contact-link,
  .team-position,
  .team-name { color: $white; }
  .team-contact-link { opacity: 1; }
}

// Style 2 (Specific styles)
.team-card-style-2 {
  position: relative;
  > img {
    display: block;
    width: 100%;
  }
  &::before,
  &::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .35s .12s;
    content: '';
    z-index: 1;
  }
  &::before { background-color: rgba($black, .25); }
  &::after {
    @extend .bg-gradient-vertical;
    opacity: 0;
  }
  .team-card-inner {
    position: absolute;
    top: 50%;
    width: 100%;
    padding: floor($grid-vertical-step / 1.2);
    transform: translateY(-45%);
    transition: all .35s .12s;
    text-align: center;
    opacity: 0;
    z-index: 5;
  }
  .team-name,
  .team-position,
  .team-contact-link { color: $white; }
  .team-name {
    margin-bottom: floor($font-size-base / 3);
    font: {
      size: floor($font-size-base * 1.25);
      weight: bold;
    }
  }
  .team-position {
    display: block;
    margin-bottom: floor($grid-vertical-step / 1.5);
    font: {
      size: $font-size-sm;
      weight: 600;
    }
    text-transform: uppercase;
  }
  .team-social-bar {
    margin: {
      top: floor($grid-vertical-step / 1.5);
      bottom: floor($grid-vertical-step / 3);
    }
  }
  .team-contact-link { opacity: 1; }
  &:hover {
    &::before { opacity: 0; }
    &::after { opacity: .7; }
    .team-card-inner {
      transform: translateY(-50%);
      opacity: 1;
    }
  }
}

// Style 3, 4 (Specific styles)
.team-card-style-3,
.team-card-style-4 {
  position: relative;
  padding: {
    top: floor($grid-vertical-step * 1.25);
    right: floor($grid-vertical-step / 1.2);
    bottom: floor($grid-vertical-step * 1.6);
    left: floor($grid-vertical-step / 1.2);
  }
  transition: all .35s;
  border: 1px solid $border-color;
  .team-thumb {
    width: 90px;
    margin: auto;
    margin-bottom: floor($grid-vertical-step / 1.4);
  }
  .team-position { margin-bottom: 0; }
  .team-contact-link {
    color: $gray-dark;
    font-weight: 600;
    > i { color: $gray !important; }
    &:hover { color: rgba($gray-dark, .6); }
  }
  .team-social-bar-wrap {
    position: absolute;
    bottom: -18px;
    left: 0;
    width: 100%;
    > .team-social-bar {
      display: table;
      margin: auto;
      background-color: $white;
      box-shadow: $box-shadow-social-btn;
    }
  }
  &:hover {
    border-color: transparent;
    box-shadow: $box-shadow-card;
  }
}
.team-card-style-4 {
  padding: {
    top: $grid-vertical-step;
    bottom: floor($grid-vertical-step * 1.3);
    left: $grid-vertical-step;
  }
  .team-name { margin-bottom: 5px; }
  .team-social-bar-wrap {
    position: relative;
    bottom: auto;
    left: auto;
    margin-top: floor($grid-vertical-step / 1.2);
    > .team-social-bar { margin: 0; }
  }
}

// Style 5
.team-card-style-5 {
  padding-bottom: $grid-vertical-step;
  transition: box-shadow .35s .12s;
  .team-thumb {
    position: relative;
    margin-bottom: $grid-vertical-step;
    &::after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity .35s .12s;
      background-color: $primary;
      content: '';
      opacity: 0;
      z-index: 1;
    }
  }
  .team-card-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: floor($grid-vertical-step / 1.5);
    padding-bottom: floor($grid-vertical-step * 1.1);
    transform: translateY(10px);
    transition: all .35s .12s;
    text-align: center;
    opacity: 0;
    z-index: 5;
  }
  .team-contact-link,
  .team-contact-link:hover { color: $white; }
  .sb-style-6.sb-light-skin,
  .sb-style-7.sb-light-skin { border-color: rgba($white, .35); }
  .team-name {
    margin-bottom: floor($grid-vertical-step / 4);
    padding: 0 floor($grid-vertical-step / 1.5);
  }
  .team-position {
    display: block;
    padding: 0 floor($grid-vertical-step / 1.5);
    transition: color .35s .12s;
  }
  &:hover {
    box-shadow: $box-shadow-card;
    .team-thumb::after { opacity: .7; }
    .team-card-inner {
      transform: translateY(0);
      opacity: 1;
    }
    .team-position { color: $primary; }
  }
}
