//
// Placeholders
// --------------------------------------------------

// Divider
%divider {
  display: block;
  position: absolute;
  top: 100%;
  left: 1px;
  width: 36px;
  height: 2px;
  @extend .bg-gradient;
  content: '';
}

// Absolute Alignment Center
%center-xy {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

// Navbar Menu
%navbar-nav {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  > li {
    display: inline-block;
    position: relative;
    margin: 0;
    > a {
      display: block;
      position: relative;
      height: $btn-height-md;
      padding: 0 15px;
      transition: color .3s;
      color: $gray-darker;
      font: {
        size: floor($font-size-base * .8125);
        weight: $navi-link-font-weight;
      }
      letter-spacing: $navi-link-letter-spacing;
      line-height: $btn-height-md;
      text: {
        transform: $navi-link-text-transform;
        decoration: none;
      }
    }
    &:hover > a,
    &.active > a { color: $primary; }
    &.active > a::before {
      display: block;
      position: absolute;
      top: 0;
      left: 16px;
      width: calc(100% - 32px);
      height: 1px;
      background-color: $primary;
      content: '';
    }
  }
}
