//
// Pricing
// --------------------------------------------------

// Default style
.pricing-card:not(.card) {
  position: relative;
  padding: {
    top: floor($grid-vertical-step / 2);
    right: floor($grid-vertical-step / 1.2);
    bottom: floor($grid-vertical-step * 1.8);
    left: floor($grid-vertical-step / 1.2);
  }
  transition: all .35s;
  border: 1px solid $border-color;
  .pricing-card-body { text-align: center; }
  &:hover {
    border-color: transparent !important;
    box-shadow: $box-shadow-card;
  }
}
.pricing-card-image {
  display: block;
  width: $pricing-image-size;
  margin: floor($grid-vertical-step / 2) auto;
  margin-bottom: $grid-vertical-step;
}
.pricing-card-price {
  color: $headings-color;
  font: {
    family: $font-family-headings;
    size: $font-size-h1;
    weight: bold;
  }
  line-height: 1;
  > span {
    display: inline-block;
    margin: {
      top: 2px;
      right: 5px;
    }
    font: {
      size: .4em;
      weight: normal;
    }
    vertical-align: top;
  }
  &.monthly,
  &.yearly {
    display: none;
    &.active { display: block; }
  }
}
.pricing-card-button {
  position: absolute;
  bottom: -($btn-height-md / 2);
  left: 0;
  width: 100%;
  text-align: center;
}

// Badge inside Pricing card
.pricing-card .badge { margin-left: -5px; }
