//
// Variables
// --------------------------------------------------

//== Colors
//
//## Gray and brand colors for use across Theme.
//---------------------------------------------------

// Grayscale
$gray-darker:           #343b43 !default;
$gray-dark:            	#404040 !default;
$gray:                  #8c8c8c !default;
$gray-light:            #e7e7e7 !default;
$gray-lighter:          #f5f5f5 !default;
$white:                 #ffffff !default;
$black:                 #000000 !default;

// Brand colors
$primary:               #ac32e4 !default;
$accent:                #e833b0 !default;
$info:		              #5695fe !default;
$success:               #33cb81 !default;
$warning:               #fc8c3a !default;
$danger:                #ff5252 !default;

// Body
$body-bg: 							$white !default;
$body-color: 						$gray-dark !default;

// Inline links
$link-color: 						$primary !default;
$link-hover-color: 			$primary !default;

// Hedings
$headings-color:	 			$gray-darker !default;

// Blockquote
$quote-text-color:      $body-color !default;
$quote-mark-color:      $gray !default;
$quote-author-color:    $gray !default;

// Borders
$border-color:          $gray-light !default;
$border-light-color:    rgba($white, .14) !default;

// Highlight color
$highlight-color:       #fff8b0 !default;

// Theme gradient
$gradient-color-1:      $primary !default;
$gradient-color-2:      $accent !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
// -------------------------------------------------------

$font-family-base: 			  'Open Sans', sans-serif !default;
$font-family-headings: 	  'Montserrat', sans-serif !default;

// Font sizes
$font-size-base:          16px !default;
$font-size-lead:          floor(($font-size-base * 1.5)) !default; // ~24px
$font-size-xl:            floor(($font-size-base * 1.125)) !default; // ~18px
$font-size-lg:            $font-size-base !default; // ~16px
$font-size-md:            floor(($font-size-base * .875)) !default; // ~14px
$font-size-sm: 				    floor(($font-size-base * .75)) !default; // ~12px
$font-size-xs:         		floor(($font-size-base * .625)) !default; // ~10px

// Headings
$font-size-h1:            floor(($font-size-base * 3)) !default; // ~48px
$font-size-h2:            floor(($font-size-base * 2.5)) !default; // ~40px
$font-size-h3:            floor(($font-size-base * 2.125)) !default; // ~34px
$font-size-h4:            floor(($font-size-base * 1.625)) !default; // ~26px
$font-size-h5:            floor(($font-size-base * 1.3125)) !default; // ~21px
$font-size-h6:            floor(($font-size-base * 1.125)) !default; // ~18px

// Display headings
$display-1:               floor(($font-size-base * 6)) !default; // ~96px
$display-2:               floor(($font-size-base * 5.5)) !default; // ~88px
$display-3:               floor(($font-size-base * 4.5)) !default; // ~72px
$display-4:               floor(($font-size-base * 3.5)) !default; // ~56px
$display-404:             floor(($font-size-base * 19)) !default;

// Font styles
$font-style-base:         normal !default;
$font-style-headings:     normal !default;
$font-style-display:      normal !default;

// Font weights
$font-weight-base:        normal !default;
$font-weight-headings:    900 !default;
$font-weight-display:     900 !default;

// Text transforms
$text-transform-base:     none !default;
$text-transform-headings: none !default;
$text-transform-display:  none !default;

// Line heights
$line-height-base:       1.5 !default;
$line-height-h1:         1.2 !default;
$line-height-h2:         1.2 !default;
$line-height-h3:         1.25 !default;
$line-height-h4:         1.3 !default;
$line-height-h5:         1.35 !default;
$line-height-h6:         1.4 !default;
$line-height-display:    1.1 !default;


//== Grid Vertical Step
// -------------------------------------------------------

$grid-vertical-step:     24px !default;


//== Border Radius
// -------------------------------------------------------

$border-radius-base: 	5px !default;


//== Box Shadow
// -------------------------------------------------------

$box-shadow-base:              0 22px 36px -12px rgba($gray-dark, .13) !default;
$box-shadow-base-pressed:      0 10px 24px -10px rgba($gray-dark, .22) !default;
$box-shadow-base-inset:        inset 0 20px 30px -12px rgba($gray-dark, .12) !default;
$box-shadow-card:              0 12px 20px 1px rgba($gray-dark, .09) !default;
$box-shadow-dropdown:          0 8px 20px -4px rgba($gray-dark, .2) !default;
$box-shadow-autocomplete:      0 6px 20px 0 rgba($gray-dark, .2) !default;
$box-shadow-input:             0 9px 24px -4px rgba($gray-dark, .16) !default;
$box-shadow-offcanvas:         22px 0 36px -12px rgba($gray-dark, .12) !default;
$box-shadow-offcanvas-reverse: -22px 0 36px -12px rgba($gray-dark, .12) !default;
$box-shadow-pagination-link:   0 12px 20px 1px rgba($gray-dark, .11) !default;
$box-shadow-social-btn:        0 12px 20px 1px rgba($gray-dark, .11) !default;
$box-shadow-shop-btn:          0 12px 20px 1px rgba($gray-dark, .11) !default;
$box-shadow-header-btns:       0 12px 20px 1px rgba($gray-dark, .13) !default;
$box-shadow-gallery-hover:     0 8px 24px 0 rgba($black, .26) !default;
$box-shadow-gallery-indicator: 0 10px 15px 0 rgba($black, .2) !default;
$box-shadow-carousel-navs:     0 10px 15px 0 rgba($black, .12) !default;
$box-shadow-contact-icon:      0 8px 20px 0 rgba($black, .11) !default;
$box-shadow-author-avatar:     0 8px 20px 0 rgba($black, .15) !default;
$box-shadow-progress:          0 8px 16px 0 rgba($black, .15) !default;
$box-shadow-post-format:       0 9px 18px 0 rgba($gray-dark, .14) !default;
$box-shadow-video-btn:         0 9px 28px 0 rgba($gray-dark, .13) !default;
$box-shadow-video-btn-hover:   0 9px 28px 0 rgba($primary, .2) !default;
$box-shadow-fullscreen-close:  0 10px 18px 0 rgba($black, .11) !default;


//== Forms
// -------------------------------------------------------

$input-height-lg:							54px !default;
$input-height:								44px !default;
$input-height-sm:							32px !default;


//== Buttons
// -------------------------------------------------------

$btn-height-lg:               54px !default;
$btn-height-md:               44px !default;
$btn-height-sm:               32px !default;
$btn-height-xs:               24px !default;

$font-size-button-lg:         floor($font-size-base * .8125) !default;
$font-size-button-md:         $font-size-sm !default;
$font-size-button-sm:         floor($font-size-base * .6875) !default;
$font-size-button-xs:         $font-size-xs !default;

$btn-font-weight:             bold !default;
$btn-text-transform:          uppercase !default;
$btn-letter-spacing:          .1em !default;


//== Social Buttons
// -------------------------------------------------------

$social-button-box-size:      36px !default;
$social-button-font-size:     $font-size-md !default;


//== Site Logo
// -------------------------------------------------------

$site-logo-width:             127px !default;


//== Navigation Links
// -------------------------------------------------------

$navi-link-font-size:         $font-size-sm !default;
$navi-link-font-weight:       600 !default;
$navi-link-text-transform:    uppercase !default;
$navi-link-letter-spacing:    .08em !default;


//== Off-Canvas
// -------------------------------------------------------

$offcanvas-width:             320px !default;


//== Tooltip
// -------------------------------------------------------

$tooltip-bg:                  darken($gray-darker, 5%) !default;
$tooltip-arrow-color:         darken($gray-darker, 5%) !default;
$tooltip-color:               $white !default;
$tooltip-opacity:             1 !default;


//== Pagination
// -------------------------------------------------------

$pagination-link-height:      36px !default;
$navigation-link-height:      44px !default;


//== Icon Box
// -------------------------------------------------------

$icon-box-icon-size:          70px !default;


//== Carousel
// -------------------------------------------------------

$carousel-navs-size:          48px !default;
$carousel-dots-size:          5px !default;


//== Testimonials
// -------------------------------------------------------

$testimonial-avatar-size:     48px !default;


//== Pricing
// -------------------------------------------------------

$pricing-image-size:          70px !default;


//== Video Player
// -------------------------------------------------------

$video-player-btn-size:       80px !default;


//== Widgets
// -------------------------------------------------------

$widget-author-avatar-size:   85px !default;
$widget-post-thumb-size:      60px !default;
$widget-product-thumb-size:   60px !default;


//== Blog
// -------------------------------------------------------

$post-author-avatar-size:     28px !default;
$post-format-size:            34px !default;


//== Shop
// -------------------------------------------------------

$cart-product-thumb-width:    110px !default;


//== Media queries (responsive breackpoints)
//## Descktop, Tablet, Mobile

$screen-xl: 1200px !default;
$screen-lg: 991px !default;
$screen-md: 768px !default;
$screen-sm: 576px !default;
$screen-xs: 360px !default;
