/*
 * CreateX | Multipurpose Bootstrap Theme
 * Copyright 2018 Createx Studio
 * Theme Styles
 */

//Import Google Fonts (Montserrat + Open Sans)
@import url('https://fonts.googleapis.com/css?family=Montserrat:600,700,900|Open+Sans:400,600,700');

// Helpers: Variables, Mixins and Placeholders
@import 'helpers/variables';
@import 'helpers/mixins';
@import 'helpers/placeholders';

// Base
@import 'base/scaffolding';
@import 'base/utilities';

// Components
@import 'components/typography';
@import 'components/code';
@import 'components/block-title';
@import 'components/forms';
@import 'components/input-group';
@import 'components/tables';
@import 'components/buttons';
@import 'components/social-buttons';
@import 'components/market-buttons';
@import 'components/navs';
@import 'components/card';
@import 'components/accordion';
@import 'components/pagination';
@import 'components/tooltip';
@import 'components/popover';
@import 'components/dropdown';
@import 'components/list-group';
@import 'components/alert';
@import 'components/toast-notification';
@import 'components/modal';
@import 'components/icon-box';
@import 'components/progress';
@import 'components/carousel';
@import 'components/gallery';
@import 'components/countdown';
@import 'components/animated-digits';
@import 'components/steps';
@import 'components/badge';
@import 'components/team';
@import 'components/testimonial';
@import 'components/pricing';
@import 'components/video-player';
@import 'components/widgets';
@import 'components/wizard';


// Layout
@import 'layout/grid';
@import 'layout/search';
@import 'layout/header';
@import 'layout/page-title';
@import 'layout/off-canvas';
@import 'layout/mobile-menu';
@import 'layout/fullscreen-overlay';

// Modules
@import 'modules/portfolio';
@import 'modules/blog';
@import 'modules/shop';
